<template>
  <v-app>
    <!--mainwrap-->
    <div class="row">
      <div class="col-md-12">
        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">
                Sinyal Hotbit 1Inch Bsc
                <button @click="kalkulatorTool('USDT', 0)" class="btn btn-info ml-2"><b-icon-calculator-fill /> Kalkulator</button>
              </h3>
              <b-form-checkbox switch v-model="auto_request">Autorun</b-form-checkbox>
            </div>
            <div class="card-toolbar">
              <span class="mr-2" v-if="axiosError.timeout.length > 0">
                <span style="color: brown; font-weight: bold; font-size: 14px" :title="axiosError.timeout.join('\n')"><b-icon-clock /> {{ axiosError.timeout.length }} Timeout</span> |
              </span>
              <span class="mr-2" v-if="axiosError.error.length > 0">
                <span style="color: #ff0000; font-weight: bold; font-size: 14px" :title="axiosError.error.join('\n')"><b-icon-exclamation-triangle /> {{ axiosError.error.length }} Error</span> |
              </span>
              <span class="mr-2" v-if="threshold > 0">
                <span style="color: #00aa00; font-weight: bold; font-size: 14px"><b-icon-bell /> {{ threshold }} Sinyal</span>
              </span>
              <button
                v-if="!(running_request > 0 || request_run)"
                :disabled="sinyal.fetching"
                @click="registerAndStartFetchSinyalHotbit1InchBsc()"
                class="btn btn-primary mr-1"
                title="Klik reset untuk menerapkan perubahan modal ke semua coin"
              >
                <b-icon-back /> Setup
              </button>
              <span v-if="running_request > 0 || request_run" class="text-muted"
                ><b-spinner small /> Update Harga Koin {{ (((total_request - running_request) / total_request) * 100).toFixed(1) }}%
              </span>
              <button v-else :disabled="sinyal.fetching" @click="startFetchSinyalHotbit1InchBsc()" class="btn btn-success" style="background: #00dd00" title="Klik refresh untuk memperbarui data">
                <b-icon-clock /> Update
              </button>
              <button v-if="running_request > 0 || request_run" @click="$router.go()" class="btn btn-danger ml-2">Stop/Refresh</button>
              <button @click="openModalLog" class="btn btn-danger ml-2"><b-icon-clock-history /> Log</button>
            </div>
          </div>
          <div class="card-body">
            <b-row>
              <b-col md="12" class="mb-5">
                <table class="table table-bordered table-sm">
                  <thead>
                    <tr>
                      <th class="text-center p-1" style="background-color: #f0f8ff">BTC/USDT</th>
                      <th class="text-center p-1" style="background-color: #ffffff">BNB/USDT</th>
                      <th class="text-center p-1" style="background-color: #f0f8ff">ETH/USDT</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="text-center font-weight-bolder p-1" style="background-color: #f0f8ff">{{ harga.btcusdt }}</td>
                      <td class="text-center font-weight-bolder p-1" style="background-color: #ffffff">{{ harga.bnbusdt }}</td>
                      <td class="text-center font-weight-bolder p-1" style="background-color: #f0f8ff">{{ harga.ethusdt }}</td>
                    </tr>
                  </tbody>
                </table>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="3">
                <b-form-group id="modal_kiri_hotbit_1inch_bsc" label="Modal Hotbit:" label-cols-sm="6" label-align-sm="right" label-for="modal_kiri_hotbit_1inch_bsc">
                  <b-form-input id="modal_kiri_hotbit_1inch_bsc" type="number" placeholder="Modal Hotbit" :value="modal_kiri_hotbit_1inch_bsc" @input="updateModalKiri" required></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group id="modal_kanan_hotbit_1inch_bsc" label="Modal 1Inch:" label-cols-sm="6" label-align-sm="right" label-for="modal_kanan_hotbit_1inch_bsc">
                  <b-form-input id="modal_kanan_hotbit_1inch_bsc" type="number" placeholder="Modal 1Inch" :value="modal_kanan_hotbit_1inch_bsc" @input="updateModalKanan" required></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group id="limit_threshold" label="Filter PNL:" label-cols-sm="6" label-align-sm="right" label-for="limit_threshold">
                  <b-input-group>
                    <b-form-input id="limit_threshold" type="number" placeholder="Filter PNL" v-model="limit_threshold" required></b-form-input>
                    <b-input-group-append is-text title="Sembunyikan yg tidak masuk kriteria">
                      <b-form-checkbox switch class="mr-n2" v-model="sinyal.table_filter" value="y" unchecked-value="" />
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col md="3" class="text-center font-weight-bold">
                <span class="">Last Fetch:{{ lastFetchSinyalHotbit1InchBsc }}</span>
              </b-col>
            </b-row>
            <b-table
              bordered
              sticky-header="900px"
              head-variant="dark"
              responsive=""
              hover
              :items="listSinyalHotbit1InchBsc"
              :fields="sinyal.fields"
              :tbody-tr-class="rowClass"
              :filter-function="filterTable"
              :filter="sinyal.table_filter"
            >
              <template slot="thead-top" style="position: sticky">
                <b-tr>
                  <b-th>Modal</b-th>
                  <b-th colspan="2">PRICE</b-th>
                  <b-th colspan="2" variant="primary">SELISIH</b-th>
                  <b-th>SYMBOL</b-th>
                  <b-th>Modal</b-th>
                  <b-th colspan="2">PRICE</b-th>
                  <b-th colspan="2" variant="primary">SELISIH</b-th>
                  <b-th style="padding: 0px;text-align: center;vertical-align: middle;font-size:18px;color:#f00;" :title="list_koin_nonaktif.map(e => e.nama_coin).join('\n')">
                    {{ list_koin_nonaktif.length > 0 ? list_koin_nonaktif.length : "" }}
                  </b-th>
                </b-tr>
              </template>
              <template #cell(symbol)="data">
                <span @click="bintangMark($event, data.value)" :class="'fa fa-star mr-2 ' + bintangFind(data.value)"></span>
                <a :href="listSettings.url_coin + data.item.coin_address" target="_pasar">{{ data.value }}</a>
                <span :class="'ml-2 badge badge-pill badge-pair-' + data.item.pair">{{ data.item.pair }}</span>
                {{ data.item.waktu_fetch }}
                <span style="color:blue">| stok</span>
                <a target="wallet" :href="'https://bscscan.com/tokenholdings?a=0xc7029e939075f48fa2d5953381660c7d01570171&q=' + data.item.coin_address">[1]</a>
                <br />
                <small style="cursor: copy" :id="'coin_address_' + data.item.symbol" @click="CopyToClipboard('coin_address_' + data.item.symbol)">{{ data.item.coin_address }}</small>
              </template>
              <template #cell(binance_ask_price)="data">
                <a
                  target="koin"
                  :href="'https://www.hotbit.io/exchange?symbol=' + data.item.symbol + '_' + data.item.pair"
                  :title="data.item.ask_xprice !== null ? data.item.ask_xprice + ' ' + data.item.pair : ''"
                >
                  <span :id="'binance_ask_price_' + data.item.symbol" v-html="parseFloat(data.value).toFixed(12)"></span>
                </a>
                <br />
                <a class="price-tool mr-2" style="cursor: copy" title="Klik untuk copy" @click="CopyToClipboard('binance_ask_price_' + data.item.symbol)"><b-icon-clipboard-plus /></a>
                <a class="price-tool" title="Klik untuk mengkalkulasi" @click="kalkulatorTool(data.item.pair, parseFloat(data.value).toFixed(8))"><b-icon-calculator /></a>
              </template>
              <template #cell(binance_bid_price)="data">
                <a
                  target="koin"
                  :href="'https://www.hotbit.io/exchange?symbol=' + data.item.symbol + '_' + data.item.pair"
                  :title="data.item.bid_xprice !== null ? data.item.bid_xprice + ' ' + data.item.pair : ''"
                >
                  <span :id="'binance_bid_price_' + data.item.symbol" v-html="parseFloat(data.value).toFixed(12)"></span>
                </a>
                <br />
                <a class="price-tool mr-2" style="cursor: copy" title="Klik untuk copy" @click="CopyToClipboard('binance_bid_price_' + data.item.symbol)"><b-icon-clipboard-plus /></a>
                <a class="price-tool" title="Klik untuk mengkalkulasi" @click="kalkulatorTool(data.item.pair, parseFloat(data.value).toFixed(12))"><b-icon-calculator /></a>
              </template>
              <template #cell(inch_usdt)="data">
                <a
                  target="koin2"
                  :href="'https://app.1inch.io/#/56/classic/swap/' + $store.state.setting.settings.busd_address + '/' + data.item.coin_address"
                  :title="data.item.bid_xprice !== null ? (parseFloat(data.value) / parseFloat(data.item.bid_pairPrice)).toFixed(12) + ' ' + data.item.pair : ''"
                >
                  <span :id="'inch_usdt' + data.item.symbol" v-html="data.value"></span>
                </a>
                <br />
                <a class="price-tool mr-2" style="cursor: copy" title="Klik untuk copy" @click="CopyToClipboard('inch_usdt' + data.item.symbol)"><b-icon-clipboard-plus /></a>
                <a class="price-tool" title="Klik untuk mengkalkulasi" @click="kalkulatorTool(data.item.pair, parseFloat(data.value).toFixed(12))"><b-icon-calculator /></a>
              </template>
              <template #cell(selisih_pnl_kiri)="data">
                <span v-bind:class="{ 'bg-primary text-white p-2': parseFloat(data.value) >= parseFloat(limit_threshold) }" v-html="data.value"></span>
              </template>
              <template #cell(selisih_pnl_kiri_persen)="data" class="text-center">
                <span v-html="data.value"></span>
              </template>
              <template #cell(inch_price)="data">
                <a
                  target="koin2"
                  :href="'https://app.1inch.io/#/56/classic/swap/' + data.item.coin_address + '/' + $store.state.setting.settings.busd_address"
                  :title="data.item.bid_xprice !== null ? (parseFloat(data.value) / parseFloat(data.item.bid_pairPrice)).toFixed(10) + ' ' + data.item.pair : ''"
                >
                  <span :id="'inch_price' + data.item.symbol" v-html="data.value"></span>
                </a>
                <br />
                <a class="price-tool mr-2" style="cursor: copy" title="Klik untuk copy" @click="CopyToClipboard('inch_price' + data.item.symbol)"><b-icon-clipboard-plus /></a>
                <a class="price-tool" title="Klik untuk mengkalkulasi" @click="kalkulatorTool(data.item.pair, parseFloat(data.value).toFixed(10))"><b-icon-calculator /></a>
              </template>
              <!-- <template #cell(inch_sum)="data">
                <span v-html="data.value"></span>
              </template> -->
              <template #cell(selisih_pnl_kanan)="data">
                <span v-bind:class="{ 'bg-primary text-white p-2': parseFloat(data.value) >= parseFloat(limit_threshold) }" v-html="data.value"></span>
              </template>
              <template #cell(selisih_pnl_kanan_persen)="data">
                <span v-html="data.value"></span>
              </template>
              <template #cell(modal_kiri)="data">
                <input class="form-control" type="number" v-on:change="indvModal($event, data.item.symbol, 'kiri')" :value="data.value" title="Ketikkan modal, tekan enter untuk memuat." />
              </template>
              <template #cell(modal_kanan)="data">
                <input class="form-control" type="number" v-on:change="indvModal($event, data.item.symbol, 'kanan')" :value="data.value" title="Ketikkan modal, tekan enter untuk memuat." />
              </template>
              <template #cell(num)="data">
                <span v-html="data.index + 1"></span>
                <b-form-checkbox switch :checked="data.item.aktif" v-on:change="ubahAktif(data.item.symbol, data.item.aktif)" />
              </template>
            </b-table>
          </div>
          <div class="card-footer">
            <b-row>
              <b-col md="12">
                <h4>Pengaturan update harga :</h4>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="4">
                <b-form-group id="axiosDelay" label="Interval" label-cols-sm="6" label-align-sm="right" label-for="axiosDelay">
                  <b-input-group>
                    <b-form-input id="axiosDelay" type="number" placeholder="Delay" :value="axiosDelay" @input="updateDelay" required></b-form-input>
                    <b-input-group-append is-text title="Sembunyikan yg tidak masuk kriteria">ms / koin</b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group id="auto_request_delay" label="Auto Delay" label-cols-sm="6" label-align-sm="right" label-for="auto_request_delay">
                  <b-input-group>
                    <b-form-input id="auto_request_delay" type="number" placeholder="Delay" :value="auto_request_delay" @input="updateAutoRequestDelay" required></b-form-input>
                    <b-input-group-append is-text title="Milidetik">ms</b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col md="5">
                <b-form-group id="axiosBatch" label="Jeda:" label-cols-sm="3" label-align-sm="right" label-for="axiosBatch">
                  <b-input-group>
                    <b-form-input id="axiosRehat" type="number" placeholder="Rehat" :value="axiosRehat" @input="updateRehat" required></b-form-input>
                    <b-input-group-append is-text title="">ms per-</b-input-group-append>
                    <b-form-input id="axiosBatch" type="number" placeholder="Rehat" :value="axiosBatch" @input="updateBatch" required></b-form-input>
                    <b-input-group-append is-text title="">koin</b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row></b-row>
          </div>
        </div>
      </div>
    </div>
    <!-- modals -->
    <b-modal ref="modal-log" title="Log Request" size="xl">
      <div style="height: 400px; overflow: auto">
        <ul>
          <li v-for="(item, index) in log_request" :key="index">{{ item }}</li>
        </ul>
      </div>
    </b-modal>
    <b-modal ref="modal-calculator" title="Kalkulator" size="md" ok-only ok-title="Tutup">
      <kalkulator :pair="kalkulator_data.pair" :harga="kalkulator_data.harga" :trigger="kalkulator_data.trigger" />
    </b-modal>
  </v-app>
  <!--mainwrap-->
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import axios from "axios";
import Swal from "sweetalert2";
import Kalkulator from "../../components/Kalkulator.vue";

export default {
  data() {
    return {
      sinyal: {
        fetching: false,
        totalRows: 1,
        currentPage: 1,
        perPage: 200,
        filter: null,
        filterOn: ["symbol"],
        table_filter: "",
        // Note 'isActive' is left out and will not appear in the rendered table
        fields: [
          {
            key: "modal_kiri",
            label: "Hotbit",
            sortable: false,
            tdClass: "text-center font-weight-bold",
            thStyle: {
              width: "10%",
            },
          },
          {
            key: "binance_ask_price",
            label: "Hotbit(Ask)",
            sortable: false,
            tdClass: "text-center font-weight-bold bg-price-binance",
          },
          {
            key: "inch_usdt",
            label: "1Inch",
            sortable: false,
            tdClass: "text-center font-weight-bold bg-price-1inch",
          },
          {
            key: "selisih_pnl_kiri",
            label: "PNL",
            sortable: false,
            tdClass: "text-center font-weight-bold",
          },
          {
            key: "selisih_pnl_kiri_persen",
            label: "%",
            sortable: false,
            tdClass: "text-center font-weight-bold",
          },
          {
            key: "symbol",
            label: "Coin",
            sortable: true,
            tdClass: "text-center font-weight-bold bg-grey-100 text-dinamis bg-puteh",
            thStyle: {
              width: "40%",
            },
          },
          {
            key: "modal_kanan",
            label: "1Inch",
            sortable: false,
            tdClass: "text-center font-weight-bold",
            thStyle: {
              width: "10%",
            },
          },
          {
            key: "inch_price",
            label: "1Inch",
            sortable: false,
            tdClass: "text-center font-weight-bold bg-price-1inch",
          },
          {
            key: "binance_bid_price",
            label: "Hotbit(Bid)",
            sortable: false,
            tdClass: "text-center font-weight-bold bg-price-binance",
          },
          // {
          //   key: "inch_sum",
          //   label: "SUM",
          //   sortable: false,
          //   tdClass: "text-center font-weight-bold",
          // },
          {
            key: "selisih_pnl_kanan",
            label: "PNL",
            sortable: false,
            tdClass: "text-center font-weight-bold",
          },
          {
            key: "selisih_pnl_kanan_persen",
            label: "%",
            sortable: false,
            tdClass: "text-center font-weight-bold",
          },
          {
            key: "num",
            label: "#",
            sortable: false,
            tdClass: "text-center font-weight-bold bg-ireng text-white",
          },
        ],
        items: [],
      },
      modal_kiri_hotbit_1inch_bsc: 0,
      modal_kanan_hotbit_1inch_bsc: 0,
      running_request: 0,
      total_request: 0,
      auto_request: false,
      auto_request_delay: 5000,
      request_run: false,
      threshold: 0,
      limit_threshold: 3,
      log_request: [],
      axiosCancelToken: axios.CancelToken,
      axiosCancel: null,
      axiosDelay: 500,
      axiosRehat: 10000,
      axiosBatch: 25,
      axiosError: {
        timeout: [],
        error: [],
      },
      harga: {
        btcusdt: 0,
        btcbidr: 0,
        ethusdt: 0,
        ethbidr: 0,
        bnbusdt: 0,
        bnbbidr: 0,
        usdtbidr: 0,
      },
      kalkulator_data: {
        pair: "USDT",
        harga: 0,
        trigger: true,
      },
      testing: "",
      jml_koin_aktif: 0,
      jml_koin: 0,
      list_koin_nonaktif: [],
      list_koin_bintang: [],
    };
  },
  components: { Kalkulator },
  watch: {
    limit_threshold() {
      this.$store.dispatch("settingSet", { name: "filter_pnl_hotbit_1inch_bsc", value: this.limit_threshold });
      this.recalculateThreshold();
    },
    running_request(newVal, oldVal) {
      if (newVal == 0 && oldVal != 0) {
        if (this.auto_request) {
          this.request_run = true;
          this.$bvToast.toast("Request selesai, me-refresh dalam " + this.auto_request_delay + "ms", {
            title: `Autorun`,
            variant: "success",
            solid: true,
          });
          setTimeout(() => {
            this.startFetchSinyalHotbit1InchBsc();
          }, this.auto_request_delay);
        } else {
          this.request_run = false;
        }
      }
    },
  },
  computed: {
    ...mapGetters(["listSinyalHotbit1InchBsc", "lastFetchSinyalHotbit1InchBsc", "listSettings", "listHotbitBookTicker", "currentUser"]),
  },
  mounted() {
    if (!(this.currentUser.level_akses == 1 || this.currentUser.fitur.find(i => i == "sinyal_hotbit_1inch_bsc") != undefined)) {
      this.$router.push({ name: "setting" });
    }
    this.modal_kiri_hotbit_1inch_bsc = this.listSettings === null ? 400 : this.listSettings.modal_kiri_hotbit_1inch_bsc || 400;
    this.modal_kanan_hotbit_1inch_bsc = this.listSettings === null ? 400 : this.listSettings.modal_kanan_hotbit_1inch_bsc || 400;
    this.limit_threshold = this.listSettings === null ? 5 : this.listSettings.filter_pnl_hotbit_1inch_bsc || 5;
    this.axiosDelay = this.listSettings === null ? 500 : this.listSettings.delay_hotbit_1inch_bsc || 500;
    this.axiosRehat = this.listSettings === null ? 10000 : this.listSettings.rehat_hotbit_1inch_bsc || 10000;
    this.axiosBatch = this.listSettings === null ? 25 : this.listSettings.batch_hotbit_1inch_bsc || 25;
    this.auto_request_delay = this.listSettings === null ? 5000 : this.listSettings.auto_request_delay || 5000;
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Market Watch Hotbit 1Inch BSC" }]);
    this.recalculateThreshold();

    //harga
    this.updateHarga();
    this.jml_koin = this.$store.state.coin.coins_hotbit_bsc.length;
    this.jml_koin_aktif = this.$store.state.coin.coins_hotbit_bsc.filter(x => x.aktif == true).length;
    this.list_koin_nonaktif = this.$store.state.coin.coins_hotbit_bsc.filter(x => x.aktif == false);

    try {
      let koin_bintang = localStorage.getItem("mw_koin_bintang");
      if (koin_bintang == null) {
        throw null;
      }
      koin_bintang = JSON.parse(koin_bintang);
      this.list_koin_bintang = koin_bintang;
    } catch (e) {
      console.log("mw_koin_bintang", e);
      localStorage.setItem("mw_koin_bintang", JSON.stringify([]));
      this.list_koin_bintang = [];
    }
  },
  methods: {
    ubahAktif(symbol, current) {
      this.$store.dispatch("updateSinyalHotbit1InchBsc", {
        symbol: symbol,
        value: {
          aktif: !current,
        },
      });
      this.$store.dispatch("updateAktifCoin", {
        nama_coin: symbol,
        edit: false,
        aktif: !current,
      });
      this.jml_koin_aktif = this.$store.state.coin.coins_hotbit_bsc.filter(x => x.aktif == true).length;
      this.list_koin_nonaktif = this.$store.state.coin.coins_hotbit_bsc.filter(x => x.aktif == false);
    },
    addLogRequest(text) {
      this.log_request.push("[" + new Date().toISOString() + "]" + text);
    },
    openModalLog() {
      this.$refs["modal-log"].show();
    },
    recalculateThreshold() {
      this.threshold = 0;
      this.listSinyalHotbit1InchBsc.forEach(item => {
        if (parseFloat(item.selisih_pnl_kanan) >= this.limit_threshold || parseFloat(item.selisih_pnl_kiri) >= this.limit_threshold) this.threshold++;
      });
    },
    getBidPrice(pair) {
      let data = this.listHotbitBookTicker.find(item => item.symbol == pair);
      if (data == undefined) {
        return 0;
      } else {
        return data.buy;
      }
    },
    clearHarga() {
      this.harga.btcusdt = "loading";
      this.harga.btcbidr = "loading";
      this.harga.ethusdt = "loading";
      this.harga.ethbidr = "loading";
      this.harga.bnbusdt = "loading";
      this.harga.bnbbidr = "loading";
      this.harga.usdtbidr = "loading";
    },
    updateHarga() {
      this.harga.btcusdt = this.getBidPrice("BTC_USDT");
      this.harga.btcbidr = this.getBidPrice("BTC_BIDR");
      this.harga.ethusdt = this.getBidPrice("ETH_USDT");
      this.harga.ethbidr = this.getBidPrice("ETH_BIDR");
      this.harga.bnbusdt = this.getBidPrice("BNB_USDT");
      this.harga.bnbbidr = this.getBidPrice("BNB_BIDR");
      this.harga.usdtbidr = this.getBidPrice("USDT_BIDR");
    },
    filterTable(row, filter) {
      // console.log("filter", filter);
      if (filter != "") {
        if (row.aktif && (parseFloat(row.selisih_pnl_kanan) >= this.limit_threshold || parseFloat(row.selisih_pnl_kiri) >= this.limit_threshold)) {
          return true;
        } else {
          return false;
        }
      }
    },
    indvModal(val, sym, k) {
      // console.log(val.target.value, sym, k);
      this.fetchSinyalHotbit1InchBsc(sym, { ["modal_" + k]: val.target.value });
    },
    updateModalKiri(e) {
      this.$store.dispatch("settingSet", { name: "modal_kiri_hotbit_1inch_bsc", value: e });
    },
    updateModalKanan(e) {
      this.$store.dispatch("settingSet", { name: "modal_kanan_hotbit_1inch_bsc", value: e });
    },
    updateDelay(e) {
      this.axiosDelay = e;
      this.$store.dispatch("settingSet", { name: "delay_hotbit_1inch_bsc", value: e });
    },
    updateAutoRequestDelay(e) {
      this.auto_request_delay = e;
      this.$store.dispatch("settingSet", { name: "auto_request_delay", value: e });
    },
    updateRehat(e) {
      this.axiosRehat = e;
      this.$store.dispatch("settingSet", { name: "rehat_hotbit_1inch_bsc", value: e });
    },
    updateBatch(e) {
      this.axiosBatch = e;
      this.$store.dispatch("settingSet", { name: "batch_hotbit_1inch_bsc", value: e });
    },
    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (parseFloat(item.selisih_pnl_kanan) >= this.limit_threshold || parseFloat(item.selisih_pnl_kiri) >= this.limit_threshold) return "bg-green";
    },
    refreshSinyalHotbit1InchBsc() {
      this.$store.dispatch("registerSinyalHotbit1InchBsc");
    },
    test2() {
      // let symbol = "ALICE";
      // let coin = this.$store.state.coin.coins_hotbit_bsc.find(item => item.nama_coin == symbol);
      let modal = 400;
      // let askPrice = 5.2024;
      // let api = BigInt("396470875063322314108");
      let api = BigInt("358516543755854128279");
      let pembagi = BigInt("1".padEnd(18 + 1, "0"));
      // let hasil = modal / askPrice;
      let hasil = (Number((api * 100n) / pembagi) / 100 - modal).toFixed(2);
      console.log(pembagi);
      console.log(hasil);
    },
    test3() {
      // amount=(10^desimal)*(modal/binance_ask_price)
      // 76887590342918700000
      let symbol = "Hotbit1Inch";
      let coin = this.$store.state.coin.coins_hotbit_bsc.find(item => item.nama_coin == symbol);
      // let pengali = BigInt("1".padEnd(parseInt(coin.desimal) + 1, "0"));
      let modal = 400;
      let askPrice = 5.2024;
      let hasil = (modal / askPrice) * Math.pow(10, parseInt(coin.desimal));
      console.log(hasil.toString());
    },
    test4() {
      let hasil = this.modal_kanan_hotbit_1inch_bsc * Math.pow(10, 18);
      console.log(hasil);
    },
    registerAndStartFetchSinyalHotbit1InchBsc2() {
      for (let iteration = 0; iteration < 110; iteration++) {
        let rehat_hotbit_1inch_bsc = 10000 * Math.floor(iteration / 25);
        let penambah = 25 * Math.floor(iteration / 25) * this.axiosDelay + rehat_hotbit_1inch_bsc;
        console.log("to " + iteration, iteration * this.axiosDelay + penambah, rehat_hotbit_1inch_bsc, penambah);
      }
    },
    registerAndStartFetchSinyalHotbit1InchBsc() {
      this.clearHarga();
      this.axiosCancel = this.axiosCancelToken.source();
      this.addLogRequest("START SETUP");
      if (this.$store.state.coin.coins_hotbit_bsc == null) {
        this.addLogRequest("KOIN KOSONG");
        Swal.fire({
          title: "Belum ada koin yg didaftarkan!",
          text: "Silahkan impor settingan dahulu",
          icon: "error",
          heightAuto: false,
        });
        return false;
      }
      this.threshold = 0;
      this.total_request = 0;
      this.$store.dispatch("clearSinyalHotbit1InchBsc").then(() => {
        this.total_request++;
        this.$store.dispatch("updateHotbitBookTicker").then(response => {
          console.log("respon", response);
          this.updateHarga();
          if (response.status != "ok") {
            Swal.fire({
              title: "Gagal memuat book ticker!",
              text: response.message,
              icon: "error",
              heightAuto: false,
            });
          } else {
            this.addLogRequest("BINANCE UPDATED");
            this.$store.dispatch("registerSinyalHotbit1InchBsc").then(() => {
              this.listSinyalHotbit1InchBsc.forEach((item, iteration) => {
                this.fetchSinyalHotbit1InchBsc(item.symbol, undefined, iteration);
              }); // forEach
            }); // dispatch registerSinyalHotbit1InchBsc
            this.$bvToast.toast("Book Ticker berhasil binance di update", {
              title: `Hotbit Book Ticker`,
              variant: "success",
              solid: true,
            });
          }
        });
      }); // dispatch clearSinyalHotbit1InchBsc
    },
    cancelFetch() {
      window.location.reload(false);
      // this.axiosCancel.cancel();
    },
    startFetchSinyalHotbit1InchBsc() {
      this.clearHarga();
      this.axiosCancel = this.axiosCancelToken.source();
      this.threshold = 0;
      this.total_request = 0;
      this.$store.dispatch("clearSinyalHotbit1InchBsc").then(() => {
        this.total_request++;
        this.$store.dispatch("updateHotbitBookTicker").then(response => {
          // console.log("respon", response);
          this.updateHarga();
          if (response.status != "ok") {
            Swal.fire({
              title: "Gagal memuat book ticker!",
              text: response.message,
              icon: "error",
              heightAuto: false,
            });
          } else {
            this.addLogRequest("BINANCE UPDATED");
            this.listSinyalHotbit1InchBsc.forEach((item, iteration) => {
              this.fetchSinyalHotbit1InchBsc(item.symbol, undefined, iteration);
            }); // forEach
            this.$bvToast.toast("Book Ticker berhasil binance di update", {
              title: `Hotbit Book Ticker`,
              variant: "success",
              solid: true,
            });
          }
        });
      }); // dispatch clearSinyalHotbit1InchBsc
    },
    fetchSinyalHotbit1InchBsc(symbol, addition, iteration) {
      // let istirahat = iteration % 25 == 0 ? iteration * 10000 : 0;
      let rehat_hotbit_1inch_bsc = this.axiosRehat * Math.floor(iteration / this.axiosBatch);
      let penambah = this.axiosDelay * iteration;
      this.axiosError.timeout = [];
      this.axiosError.error = [];
      this.running_request += 2;
      this.total_request += 2;
      let sinyal = this.listSinyalHotbit1InchBsc.find(item => item.symbol == symbol);
      let coin = this.$store.state.coin.coins_hotbit_bsc.find(item => item.nama_coin == symbol);
      if (addition == undefined) {
        addition = {};
      }
      let askPrice = this.$store.getters.getHotbitBookCoinPriceAsk(symbol);
      let bidPrice = this.$store.getters.getHotbitBookCoinPriceBid(symbol);
      // console.log("ask", askPrice);
      // if (penambah !== undefined) {
      //   return true;
      // }
      // if (iteration !== null) {
      //   setTimeout(() => {
      //     this.running_request -= 2;
      //   }, iteration * 10);
      //   return true;
      // }
      setTimeout(() => {
        this.$store
          .dispatch("updateSinyalHotbit1InchBsc", {
            symbol: symbol,
            value: {
              ...addition,
              binance_ask_price: askPrice.price,
              ask_xprice: askPrice.xprice !== undefined ? askPrice.xprice : null,
              binance_bid_price: bidPrice.price,
              bid_xprice: bidPrice.xprice !== undefined ? bidPrice.xprice : null,
              ask_pairPrice: askPrice.pairPrice !== undefined ? askPrice.pairPrice : null,
              bid_pairPrice: bidPrice.pairPrice !== undefined ? bidPrice.pairPrice : null,
              selisih_pnl_kiri: "<div class='spinner-border spinner-border-sm'></div>",
              inch_usdt: "<div class='spinner-border spinner-border-sm'></div>",
              selisih_pnl_kiri_persen: "<div class='spinner-border spinner-border-sm'></div>",
              inch_sum: "<div class='spinner-border spinner-border-sm'></div>",
              inch_price: "<div class='spinner-border spinner-border-sm'></div>",
              selisih_pnl_kanan: "<div class='spinner-border spinner-border-sm'></div>",
              selisih_pnl_kanan_persen: "<div class='spinner-border spinner-border-sm'></div>",
            },
          })
          .then(() => {
            if (sinyal.binance_ask_price == 0) {
              this.$store.dispatch("updateSinyalHotbit1InchBsc", {
                symbol: symbol,
                value: {
                  ...addition,
                  selisih_pnl_kiri: 0,
                  inch_usdt: 0,
                  selisih_pnl_kiri_persen: 0,
                  inch_sum: 0,
                  inch_price: 0,
                  selisih_pnl_kanan: 0,
                  selisih_pnl_kanan_persen: 0,
                },
              });
              this.running_request -= 2;
              return true;
            }
            let modal_kiri = addition.modal_kiri != undefined ? addition.modal_kiri : sinyal.modal_kiri;
            let modal_kanan = addition.modal_kanan != undefined ? addition.modal_kanan : sinyal.modal_kanan;
            // console.log(modal_kiri / askPrice);
            // console.log(((modal_kiri / askPrice) * Math.pow(10, parseInt(coin.desimal))).toLocaleString("fullwide", { useGrouping: false }));
            // console.log(parseInt((modal_kiri / askPrice) * Math.pow(10, parseInt(coin.desimal))));
            // let amount = BigInt(parseInt((modal_kiri / askPrice) * Math.pow(10, parseInt(coin.desimal)))).toString();
            // console.log(amount);
            let amount = ((modal_kiri / askPrice.price) * Math.pow(10, parseInt(coin.desimal))).toLocaleString("fullwide", { useGrouping: false, maximumFractionDigits: 0 });
            axios
              .get("https://bsc.api.0x.org/swap/v1/quote", {
                cancelToken: this.axiosCancel.token,
                params: {
                  buyToken: this.$store.state.setting.settings.busd_address,
                  sellToken: coin.coin_address,
                  sellAmount: amount,
                },
                timeout: (this.$store.state.setting.settings.timeout_request || 15) * 1000,
              })
              .then(response => {
                this.running_request -= 1;
                let data = response.data;

                // kalkulasi selisih pnl kiri
                let api = BigInt(data.buyAmount);
                let pembagi = BigInt("1".padEnd(19, "0"));
                let selisih_pnl_kiri = Number((api * 100n) / pembagi) / 100 - modal_kiri;

                // inch_usdt
                // ( modal_kiri + API_INCH_FROM ) / ( modal_kiri / binance_ask_price )
                let inch_usdt = (parseInt(modal_kiri) + selisih_pnl_kiri) / (parseInt(modal_kiri) / askPrice.price);

                // kalkulasi selisih_pnl_kiri_persen
                // ((inch_usdt-binance_ask_price)/binance_ask_price)*100
                let selisih_pnl_kiri_persen = ((inch_usdt - askPrice.price) / askPrice.price) * 100;

                this.$store.dispatch("updateSinyalHotbit1InchBsc", {
                  symbol: symbol,
                  value: {
                    selisih_pnl_kiri: selisih_pnl_kiri.toFixed(2),
                    inch_usdt: inch_usdt.toFixed(12),
                    selisih_pnl_kiri_persen: selisih_pnl_kiri_persen.toFixed(2),
                  },
                });

                let amount2 = BigInt(modal_kanan * Math.pow(10, 18)).toLocaleString("fullwide", { useGrouping: false, maximumFractionDigits: 0 });
                axios
                  .get("https://bsc.api.0x.org/swap/v1/quote", {
                    cancelToken: this.axiosCancel.token,
                    params: {
                      buyToken: coin.coin_address,
                      sellToken: this.$store.state.setting.settings.busd_address,
                      sellAmount: amount2,
                    },
                    timeout: (this.$store.state.setting.settings.timeout_request || 15) * 1000,
                  })
                  .then(response => {
                    this.running_request -= 1;
                    let data2 = response.data;

                    // kalkulasi inch_sum
                    let api2 = BigInt(data2.buyAmount);
                    let pembagi = BigInt("1".padEnd(parseInt(coin.desimal) + 1, "0"));
                    let inch_sum = Number((api2 * 100n) / pembagi) / 100;

                    // kalkulasi inch_price
                    // modal_kanan/API_INCH_TO
                    let inch_price = inch_sum == 0 ? 0 : modal_kanan / inch_sum;

                    // kalkulasi selisih_pnl_kanan
                    // ((API_INCH_TO*binance_bid_price)-modal_kanan)
                    let selisih_pnl_kanan = inch_sum * bidPrice.price - modal_kanan;

                    // kalkulasi selisih_pnl_kanan_persen
                    // ((binance_bid_price-inch_price)/inch_price)*100
                    let selisih_pnl_kanan_persen = inch_sum == 0 ? 0 : ((bidPrice.price - inch_price) / inch_price) * 100;
                    if (selisih_pnl_kanan >= this.limit_threshold || selisih_pnl_kiri >= this.limit_threshold) this.threshold++;
                    let waktu = new Date();
                    this.$store.dispatch("updateSinyalHotbit1InchBsc", {
                      symbol: symbol,
                      value: {
                        inch_sum: inch_sum.toFixed(2),
                        inch_price: inch_price.toFixed(12),
                        selisih_pnl_kanan: selisih_pnl_kanan.toFixed(2),
                        selisih_pnl_kanan_persen: selisih_pnl_kanan_persen.toFixed(2),
                        waktu_fetch: ("0" + waktu.getHours()).slice(-2) + ":" + ("0" + waktu.getMinutes()).slice(-2) + ":" + ("0" + waktu.getSeconds()).slice(-2),
                      },
                    });
                  })
                  .catch(error => {
                    this.running_request -= 1;
                    let placeholder;
                    if (error.code == "ECONNABORTED") {
                      //TIMEOUT
                      placeholder = "timeout";
                      this.addLogRequest("TIMEOUT: " + symbol + " " + coin.coin_address);
                      this.axiosError.timeout.push(symbol);
                    } else if (error.response != undefined) {
                      placeholder = "error";
                      let message = error.response.data.message || "general error";
                      this.addLogRequest("ERROR: " + symbol + " : " + message + "<br>" + error.request.responseURL);
                      this.axiosError.error.push(symbol);
                    } else if (axios.isCancel(error)) {
                      this.addLogRequest("CANCELLED: " + symbol + " " + coin.coin_address);
                      placeholder = "canceled";
                      this.axiosError.error.push(symbol);
                    } else {
                      this.addLogRequest("NETWORK ERROR: " + symbol + " " + coin.coin_address + " Request ditolak oleh Hotbit1Inch (CORS)");
                      placeholder = "error";
                      this.axiosError.error.push(symbol);
                    }
                    // this.$bvToast.toast("Error saat menghubungi Hotbit1Inch untuk koin " + symbol, {
                    //   title: `API Error`,
                    //   variant: "danger",
                    //   solid: false,
                    // });
                    let waktu = new Date();
                    this.$store.dispatch("updateSinyalHotbit1InchBsc", {
                      symbol: symbol,
                      value: {
                        inch_sum: placeholder,
                        inch_price: placeholder,
                        selisih_pnl_kanan: placeholder,
                        selisih_pnl_kanan_persen: placeholder,
                        waktu_fetch: ("0" + waktu.getHours()).slice(-2) + ":" + ("0" + waktu.getMinutes()).slice(-2) + ":" + ("0" + waktu.getSeconds()).slice(-2),
                      },
                    });
                    console.log("Fetching Hotbit1Inch error", error);
                  });
              })
              .catch(error => {
                // console.log(error.code);
                this.running_request -= 2;
                let placeholder;
                if (error.code == "ECONNABORTED") {
                  //TIMEOUT
                  placeholder = "timeout";
                  this.addLogRequest("TIMEOUT: " + symbol + " " + coin.coin_address);
                  this.axiosError.timeout.push(symbol);
                } else if (error.response != undefined) {
                  placeholder = "error";
                  let message = error.response.data.message || "general error";
                  this.addLogRequest("ERROR: " + symbol + " : " + message + " \n " + error.request.responseURL + " \n Cek nama koin!");
                  this.axiosError.error.push(symbol);
                } else if (axios.isCancel(error)) {
                  this.addLogRequest("CANCELLED: " + symbol + " " + coin.coin_address);
                  placeholder = "canceled";
                  this.axiosError.error.push(symbol);
                } else {
                  this.addLogRequest("NETWORK ERROR: " + symbol + " " + coin.coin_address + " Request ditolak oleh Hotbit1Inch (CORS)");
                  placeholder = "error";
                  this.axiosError.error.push(symbol);
                }
                // this.$bvToast.toast("Error saat menghubungi Hotbit1Inch untuk koin " + symbol + ":\n" + error.response.data.message, {
                //   title: `Error 1inch`,
                //   variant: "danger",
                //   solid: false,
                // });
                let waktu = new Date();
                this.$store.dispatch("updateSinyalHotbit1InchBsc", {
                  symbol: symbol,
                  value: {
                    selisih_pnl_kiri: placeholder,
                    inch_usdt: placeholder,
                    selisih_pnl_kiri_persen: placeholder,
                    inch_sum: placeholder,
                    inch_price: placeholder,
                    selisih_pnl_kanan: placeholder,
                    selisih_pnl_kanan_persen: placeholder,
                    waktu_fetch: ("0" + waktu.getHours()).slice(-2) + ":" + ("0" + waktu.getMinutes()).slice(-2) + ":" + ("0" + waktu.getSeconds()).slice(-2),
                  },
                });
                console.log("Fetching Hotbit1Inch error", error);
              });
          }); // dispatch updateSinyalHotbit1InchBsc
      }, rehat_hotbit_1inch_bsc + penambah);
    },
    bintangFind(koin) {
      const idx = this.list_koin_bintang.findIndex(x => x.koin == koin);
      if (idx >= 0) {
        return this.list_koin_bintang[idx].class;
      } else {
        return "bintang-white";
      }
    },
    bintangMark(event, koin) {
      console.log(koin);
      const idx = this.list_koin_bintang.findIndex(x => x.koin == koin);
      let newclass = "";
      if (idx >= 0) {
        const old = this.list_koin_bintang.splice(idx, 1);
        if (old[0].class == "bintang-white") {
          newclass = "bintang-green";
        } else if (old[0].class == "bintang-green") {
          newclass = "bintang-red";
        } else {
          newclass = "bintang-white";
        }
        this.list_koin_bintang.push({
          koin,
          class: newclass,
        });
      } else {
        newclass = "bintang-green";
        this.list_koin_bintang.push({
          koin,
          class: newclass,
        });
      }

      localStorage.setItem("mw_koin_bintang", JSON.stringify(this.list_koin_bintang));

      if (newclass == "bintang-white") {
        event.target.classList.add("bintang-white");
        event.target.classList.remove("bintang-red");
        event.target.classList.remove("bintang-green");
      } else if (newclass == "bintang-green") {
        event.target.classList.remove("bintang-white");
        event.target.classList.remove("bintang-red");
        event.target.classList.add("bintang-green");
      } else {
        event.target.classList.remove("bintang-white");
        event.target.classList.add("bintang-red");
        event.target.classList.remove("bintang-green");
      }
    },
    CopyToClipboard(containerid) {
      console.log(containerid);
      let selrange = document.createRange();
      selrange.selectNode(document.getElementById(containerid));
      window.getSelection().removeAllRanges(); // clear current selection
      window.getSelection().addRange(selrange); // to select text
      document.execCommand("copy");
      window.getSelection().removeAllRanges(); // to deselect
      this.$bvToast.toast("data dicopy", {
        title: `Clipboard`,
        variant: "info",
        solid: false,
      });
    },
    kalkulatorTool(pair, harga) {
      this.kalkulator_data.pair = pair;
      this.kalkulator_data.harga = harga;
      this.kalkulator_data.trigger = !this.kalkulator_data.trigger;
      this.$refs["modal-calculator"].show();
    },
  },
};
</script>
